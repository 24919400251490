import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Tabs, Tab, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, Button, TablePagination, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CredentialsForm from './CredentialsForm';

const fetchProjectTargets = async (projectId) => {
  try {
    const response = await axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/targets/${projectId}`);
    return response.data;
    
  } catch (error) {
    console.error('Error fetching project targets:', error);
    return [];
  }
};

const fetchActivityLinks = async (projectId) => {
  try {
    const response = await axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/activity-links/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching activity links:', error);
    return [];
  }
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ display: value === index ? 'block' : 'none' }} // Adjust the visibility
    >
      {children}
    </Box>
  );
};

const CredentialsTab = ({ projectId }) => {
  const [value, setValue] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [currentCredential, setCurrentCredential] = useState(null);
  const [currentCategory, setCurrentCategory] = useState('');
  const [credentialsData, setCredentialsData] = useState({});
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [searchQuery, setSearchQuery] = useState(''); // Search query

  useEffect(() => {
    const fetchData = async () => {
      const targets = await fetchProjectTargets(projectId);
      // console.log( "Targets:", targets);
      
      const links = await fetchActivityLinks(projectId);

      const combinedData = targets.reduce((acc, target) => {
        if (!acc[target.activity_type]) {
          acc[target.activity_type] = [];
        }
        return acc;
      }, {});

      links.forEach(link => {
        if (!combinedData[link.activity_type]) {
          combinedData[link.activity_type] = [];
        }
        combinedData[link.activity_type].push(link);
      });

      setCredentialsData(combinedData);
    };

    fetchData();
  }, [projectId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (credential, category) => {
    setCurrentCredential(credential);
    setCurrentCategory(category);
    setOpenForm(true);
  };

  const handleAddNew = (category) => {
    setCurrentCredential(null);
    setCurrentCategory(category);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setCurrentCredential(null);
    setCurrentCategory('');
  };

  const handleDelete = async (credentialId) => {
    if (window.confirm('Are you sure you want to delete this credential?')) {
      try {
        await axios.delete(`https://seo.digitalhive.in/seo/wp-json/custom/v1/activity-links/${credentialId}`);
        toast.success('Credential deleted successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCredentialsData((prevData) => {
          const updatedData = { ...prevData };
          Object.keys(updatedData).forEach((category) => {
            updatedData[category] = updatedData[category].filter((cred) => cred.id !== credentialId);
          });
          return updatedData;
        });
      } catch (error) {
        console.error('Error deleting credential:', error);
        toast.error('Failed to delete credential.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Text copied to clipboard!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }).catch((err) => {
      toast.error('Failed to copy text: ' + err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Search Input */}
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="credentials tabs" variant="scrollable" scrollButtons>
          {Object.keys(credentialsData).map((category, index) => (
            <Tab label={category} key={category} />
          ))}
        </Tabs>
      </Box>
      {Object.keys(credentialsData).map((category, index) => (
        <TabPanel value={value} index={index} key={category}>
          <Typography variant="h6" gutterBottom>
            {category}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>Domain Authority</TableCell>
                  <TableCell>Spam Score</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {credentialsData[category]
                  .filter((credential) =>
                    credential.url.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    credential.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    credential.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    credential.password.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((credential, idx) => (
                    <TableRow key={idx}>
                      <TableCell><a href={credential.url} target="_blank" rel="noopener noreferrer">{credential.url}</a></TableCell>
                      <TableCell>{credential.title}</TableCell>
                      <TableCell>
                        {credential.username}
                        <IconButton onClick={() => copyToClipboard(credential.username)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {credential.password}
                        <IconButton onClick={() => copyToClipboard(credential.password)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{credential.domain_authority}</TableCell>
                      <TableCell>{credential.spam_score}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEdit(credential, category)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDelete(credential.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {credentialsData[category].length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No credentials added for this category.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={credentialsData[category]?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleAddNew(category)}
            sx={{ mt: 2 }}
          >
            Add New Credential
          </Button>
          <Dialog open={openForm} onClose={handleCloseForm} maxWidth="md" fullWidth>
            <CredentialsForm website={currentCredential} category={currentCategory} projectId={projectId} onClose={handleCloseForm} />
          </Dialog>
        </TabPanel>
      ))}
    </Box>
  );
};

export default CredentialsTab;
