import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, LinearProgress, Button, CircularProgress } from '@mui/material';
import CompletedTasksDialog from './CompletedTasksDialog';
import axios from 'axios';

const TaskProgress = ({ projectId }) => {
  const [taskData, setTaskData] = useState(null);
  const [targets, setTargets] = useState([]);
  const [achievedData, setAchievedData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTaskData, setCurrentTaskData] = useState([]);

  useEffect(() => {
    if (projectId) {
      axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/targets/${projectId}`)
        .then(response => {
          setTargets(response.data);
        })
        .catch(error => {
          console.error('Error fetching targets:', error);
        });

      axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/works/${projectId}`)
        .then(response => {
          setAchievedData(response.data);
        })
        .catch(error => {
          console.error('Error fetching achieved data:', error);
        });
    }
  }, [projectId]);

  useEffect(() => {
    if (targets.length > 0 && achievedData.length > 0) {
      const combinedData = targets.map(target => {
        const achievedEntries = achievedData
          .filter(achieved => achieved.activity_type === target.activity_type)
          .sort((a, b) => new Date(b.date) - new Date(a.date)); // Assuming you have a 'date' field to sort by

        const achieved = achievedEntries.length > 0 ? achievedEntries[0].target_achieved : 0;

        const completedTasks = achievedEntries.flatMap(item => item.completedTasks || []);

        return {
          ...target,
          achieved,
          completedTasks
        };
      });
      setTaskData(combinedData);
    }
  }, [targets, achievedData]);

  const handleOpenDialog = (task) => {
    setCurrentTaskData(task.completedTasks);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentTaskData([]);
  };

  if (!taskData) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" component="div" gutterBottom>
        Work Done
      </Typography>
      {taskData.map((task, index) => {
        const isCompleted = task.achieved >= task.target;
        return (
          <Paper key={index} elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">{task.activity_type}</Typography>
              <Button variant="outlined" size="small" onClick={() => handleOpenDialog(task)}>
                View
              </Button>
            </Box>
            <LinearProgress
              variant="determinate"
              value={(task.achieved / task.target) * 100}
              sx={{
                mt: 1,
                bgcolor: 'lightgrey',
                '& .MuiLinearProgress-bar': {
                  bgcolor: isCompleted ? 'green' : 'primary.main',
                },
              }}
            />
            <Typography variant="body2" color="textSecondary">
              {`Target: ${task.target} | Achieved: ${task.achieved}`}
            </Typography>
          </Paper>
        );
      })}
      <CompletedTasksDialog open={openDialog} onClose={handleCloseDialog} taskData={currentTaskData} />
    </Box>
  );
};

export default TaskProgress;
  