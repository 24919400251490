import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import axios from 'axios';
import ReportList from './ReportList';

const ReportsView = ({ projectId,project }) => {
  const [reports, setReports] = useState([]);

  // useEffect(() => {
  //   const fetchReports = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const response = await axios.get(
  //         `https://seo.digitalhive.in/seo/wp-json/custom/v1/reports/${projectId}`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //       setReports(response.data);
  //     } catch (error) {
  //       console.error("Error fetching reports:", error);
  //     }
  //   };

  //   if (projectId) {
  //     fetchReports();
  //   }
  // }, [projectId]);

  return (
    <Box sx={{ mt: 4 }}>
      {/* <Typography variant="h5">Reports for Project ID: {projectId}</Typography>
      {reports.length === 0 ? (
        <Typography>No reports found for this project.</Typography>
      ) : (
        <Grid container spacing={3}>
          {reports.map((report, index) => (
            <Grid item xs={12} key={index}>
              <Paper sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1">Report Name: {report}</Typography>
                <Typography variant="body2">
                  <a href={report} target="_blank" rel="noopener noreferrer">
                    View Report
                  </a>
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )} */}
      <ReportList project={project}/>
    </Box>
  );
};

export default ReportsView;
