// src/components/FloatingActionButton.js
import React, { useState } from "react";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import ReportIcon from "@mui/icons-material/Report";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";
import DoneIcon from "@mui/icons-material/Done";
import AddWorkDialog from "./AddWorkDialog";
import KeywordsRankDialog from "./KeywordsRankDialog";
import ReportModal from "./ReportModal";
import SendReportForm from "./SendReportForm";
import ProfileLogoDialog from "./ProfileLogoDialog";
import WorkDoneModal from "./WorkDoneModal";
const actions = [
  { icon: <AddCircleOutlineIcon />, name: "Add Work", action: "addWork" },
  {
    icon: <BarChartIcon />,
    name: "Add Keywords Rank",
    action: "addKeywordsRank",
  },
  { icon: <ReportIcon />, name: "Generate Report", action: "generateReport" },
  { icon: <SendIcon />, name: "Send Report", action: "sendReport" },
  { icon: <PersonIcon />, name: "Profile Logo", action: "profileLogo" },
  { icon: <DoneIcon />, name: "Work Done", action: "workDone" },
];

const FloatingActionButton = ({ projectId, currentUser, project }) => {
  const [openAddWork, setOpenAddWork] = useState(false);
  const [openKeywordsRank, setOpenKeywordsRank] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openSendReport, setOpenSendReport] = useState(false);
  const [openProfileLogo, setOpenProfileLogo] = useState(false);
  const [openWorkDone, setOpenWorkDone] = useState(false);

  const handleOpenAddWork = () => setOpenAddWork(true);
  const handleCloseAddWork = () => setOpenAddWork(false);

  const handleOpenKeywordsRank = () => setOpenKeywordsRank(true);
  const handleCloseKeywordsRank = () => setOpenKeywordsRank(false);

  const handleOpenReport = () => setOpenReport(true);
  const handleCloseReport = () => setOpenReport(false);

  const handleOpenSendReport = () => setOpenSendReport(true);
  const handleCloseSendReport = () => setOpenSendReport(false);

  const handleOpenProfileLogo = () => setOpenProfileLogo(true);
  const handleCloseProfileLogo = () => setOpenProfileLogo(false);

  const handleOpenWorkDone = () => setOpenWorkDone(true); // Open Work Done Modal
  const handleCloseWorkDone = () => setOpenWorkDone(false); // Close Work Done Modal

  const handleClick = (action) => {
    switch (action) {
      case "addWork":
        handleOpenAddWork();
        break;
      case "addKeywordsRank":
        handleOpenKeywordsRank();
        break;
      case "generateReport":
        handleOpenReport();
        break;
      case "sendReport":
        handleOpenSendReport();
        break;
      case "profileLogo":
        handleOpenProfileLogo();
        break;
      case "workDone": // Handle Work Done action
        handleOpenWorkDone();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={
              action.href
                ? () => (window.location.href = action.href)
                : () => handleClick(action.action)
            }
          />
        ))}
      </SpeedDial>
      <ProfileLogoDialog
        open={openProfileLogo}
        onClose={handleCloseProfileLogo}
        project={project}
      />
      <AddWorkDialog
        open={openAddWork}
        onClose={handleCloseAddWork}
        projectId={projectId}
        currentUser={currentUser}
      />
      <KeywordsRankDialog
        open={openKeywordsRank}
        onClose={handleCloseKeywordsRank}
        projectId={projectId}
      />
      <ReportModal
        open={openReport}
        onClose={handleCloseReport}
        projectId={projectId}
        project={project}
      />
      <SendReportForm
        open={openSendReport}
        onClose={handleCloseSendReport}
        projectId={projectId}
        project={project}
      />
      <WorkDoneModal open={openWorkDone} onClose={handleCloseWorkDone} projectId={projectId} /> {/* Work Done Modal */}
    </>
  );
};

export default FloatingActionButton;
