import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, Box, MenuItem } from '@mui/material';
import axios from 'axios';
import { toast } from "react-toastify";

const AddWorkDialog = ({ open, onClose, projectId, currentUser }) => {
  const [formData, setFormData] = useState({
    activity_type: '',
    activity_url: '',
    title: '',
    tag: '',
    landing_page: '',
    work_description: '',
    user: "",
  });
  const [activities, setActivities] = useState([]);
  const [activityLinks, setActivityLinks] = useState([]);

  useEffect(() => {
    if (projectId) {
      axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/targets/${projectId}`)
        .then(response => {
          setActivities(response.data.map(item => item.activity_type));
        })
        .catch(error => {
          console.error('Error fetching activities:', error);
        });
    }
  }, [projectId]);

  const handleActivityChange = (e) => {
    e.preventDefault(); 
    const { value } = e.target;
    setFormData({
      ...formData,
      activity_type: value,
      activity_url: '',
    });

    axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/activity-links/${projectId}`)
      .then(response => {
        setActivityLinks(response.data.filter(link => link.activity_type === value));
      })
      .catch(error => {
        console.error('Error fetching activity links:', error);
        setActivityLinks([]);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log("Current User:", currentUser);
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser || !currentUser.name) {
      toast.error("User information is missing. Please try again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;

    }
  
    try {
      await axios.post(`https://seo.digitalhive.in/seo/wp-json/custom/v1/works`, {
        ...formData,
        project_id: projectId,
        user: currentUser.name,
      });
      toast.success("Work Added Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error adding work:', error);
      toast.error(error.response?.data || "Error adding work.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      BackdropProps={{ invisible: true }}
    >
      <DialogTitle>Add Work</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                name="activity_type"
                label="Activity"
                fullWidth
                variant="outlined"
                required
                value={formData.activity_type}
                onChange={handleActivityChange}
              >
                {activities.map((activity) => (
                  <MenuItem key={activity} value={activity}>
                    {activity}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                name="activity_url"
                label="Activity URL"
                fullWidth
                variant="outlined"
                required
                value={formData.activity_url}
                onChange={handleChange}
              >
                {activityLinks.map((link) => (
                  <MenuItem key={link.url} value={link.url}>
                    {link.url}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Title"
                fullWidth
                variant="outlined"
                required
                value={formData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="tag"
                label="Tag"
                fullWidth
                variant="outlined"
                required
                value={formData.tag}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="landing_page"
                label="Landing Page"
                fullWidth
                variant="outlined"
                required
                value={formData.landing_page}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="work_description"
                label="Work Description"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                required
                value={formData.work_description}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose} color="secondary">Cancel</Button>
            <Button type="submit" color="primary">Add Work</Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkDialog;
