// src/components/CreateProjectForm.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Grid, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateProjectForm = () => {
  const [formData, setFormData] = useState({
    pname: '',
    shortbio: '',
    longbio: '',
    status: '',
    dateadded: '',
    address: '',
    phno: '',
    email: '',
    gmailid: '',
    password: '',
    category: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form data to backend or store in state
    console.log(formData);
    navigate('/');
  };

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Create Project
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="pname"
            label="Project Name"
            fullWidth
            variant="outlined"
            value={formData.pname}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="shortbio"
            label="Short Bio"
            fullWidth
            variant="outlined"
            value={formData.shortbio}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="longbio"
            label="Long Bio"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={formData.longbio}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            name="status"
            label="Status"
            fullWidth
            variant="outlined"
            value={formData.status}
            onChange={handleChange}
            required
          >
            {statuses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="dateadded"
            label="Date Added"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={formData.dateadded}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="address"
            label="Address"
            fullWidth
            variant="outlined"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="phno"
            label="Phone Number"
            fullWidth
            variant="outlined"
            value={formData.phno}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="gmailid"
            label="Gmail ID"
            fullWidth
            variant="outlined"
            value={formData.gmailid}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="password"
            label="Password"
            fullWidth
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="category"
            label="Category"
            fullWidth
            variant="outlined"
            value={formData.category}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateProjectForm;
