import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Paper } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {jwtDecode} from 'jwt-decode'; // Ensure correct import

const LoginPage = ({ setLoggedIn, setUserRole }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://seo.digitalhive.in/seo/wp-json/jwt-auth/v1/token', {
        username,
        password,
      });
      const token = response.data.token;
      localStorage.setItem('token', token);
      const decoded = jwtDecode(token);
      setLoggedIn(true);
      setUserRole(decoded.role); // Assuming 'role' is part of the decoded token
    } catch (error) {
      toast.error('Login failed!');
      console.error('Login error:', error);
    }
  };

  return (
    <LoginForm
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
      handleLogin={handleLogin}
    />
  );
};

const LoginForm = ({ username, setUsername, password, setPassword, handleLogin }) => (
  <Box
    sx={{
      background: 'linear-gradient(to bottom right, #2196f3, #f44336)',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
        }}
      >
        <Typography component="h1" variant="h5" align="center" sx={{ fontWeight: 600 }}>
          Welcome
        </Typography>
        <Typography component="h2" variant="body1" align="center" sx={{ color: 'gray', mb: 3 }}>
          Please sign in to continue
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField {...textFieldProps('Username', username, setUsername)} />
          <TextField {...textFieldProps('Password', password, setPassword, true)} />
          <Button type="submit" fullWidth variant="contained" sx={buttonStyle}>
            Sign In
          </Button>
        </Box>
      </Paper>
      <ToastContainer />
    </Container>
  </Box>
);

const textFieldProps = (label, value, onChange, isPassword = false) => ({
  variant: "outlined",
  margin: "normal",
  required: true,
  fullWidth: true,
  label,
  autoComplete: isPassword ? "current-password" : "username",
  type: isPassword ? "password" : "text",
  autoFocus: !isPassword,
  value,
  onChange: (e) => onChange(e.target.value),
  sx: {
    borderRadius: '10px',
    background: 'rgba(255, 255, 255, 0.6)',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '10px',
      },
    },
  }
});

const buttonStyle = {
  mt: 3,
  mb: 2,
  borderRadius: '50px',
  backgroundColor: '#f44336',
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
};

export default LoginPage;
