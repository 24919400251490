import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import dhlogo from "../assets/dhlogo.jpg"

const ProfileLogoDialog = ({ open, onClose, project }) => {

  const {
    acf_fields: {
      images: { image1 = { url: dhlogo }, image2, image3 } = {}, // Default image if image1 is not available
    } = {},
  } = project || {}; // Handle the case where project might be null or undefined

  // Function to get image sizes
  const getImageSizes = (image) => {
    if (!image || !image.sizes) return [];
    const { sizes } = image;
    return Object.keys(sizes)
      .filter((size) => !size.includes('-width') && !size.includes('-height') && sizes[size]) // Filter out width and height keys and check if the URL is not empty
      .map((size) => ({
        size,
        url: sizes[size],
      }));
  };

  const logoSizes = getImageSizes(image1);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Profile Logos</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h6" gutterBottom>
            Download Logos
          </Typography>
          <Grid container spacing={2}>
            {logoSizes.map((logo, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box textAlign="center">
                  <Typography variant="body1" gutterBottom>
                    {logo.size}
                  </Typography>
                  <img src={logo.url} alt={`${logo.size} logo`} style={{ maxWidth: '100%' }} />
                  <Tooltip title={`Download ${logo.size} logo`}>
                    <IconButton
                      href={logo.url}
                      download
                      sx={{ mt: 1 }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileLogoDialog;
