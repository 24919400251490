  // src/pages/ProjectDashboard.js
  import React, { useState, useEffect } from "react";
  import { useParams } from "react-router-dom";
  import axios from "axios";
  import { Container, Grid, Paper } from "@mui/material";
  import { toast, ToastContainer } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import ProjectDetailsCard from "../components/ProjectDetailsCard";
  import ProjectDescription from "../components/ProjectDescription";
  import FloatingActionButton from "../components/FloatingActionButton";
  import KeywordRanksLineChart from "../components/KeywordRanksLineChart";
  import TaskProgress from "../components/TaskProgress";
  import KeywordPriorityList from "../components/KeywordPriorityList";
  import ReportList from "../components/ReportList";
  import CredentialsTab from "../components/CredentialsTab";

  const ProjectDashboard = ({currentUser}) => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [assignedUsers, setAssignedUsers] = useState([]);

    useEffect(() => {
      const fetchProject = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");

        try {
          const response = await axios.get(
            `https://seo.digitalhive.in/seo/wp-json/custom/v1/projects/${projectId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProject(response.data);
          // toast.success("Project loaded successfully");
        } catch (error) {
          if (error.response && error.response.status === 403) {
            toast.error("You do not have access to this project.");
          } else if (error.response && error.response.status === 404) {
            toast.error("Project not found.");
          } else {
            toast.error("An error occurred while fetching the project.");
          }
          console.error("Error fetching project:", error);
        } finally {
          setLoading(false);
        }
      };

      const fetchAssignedUsers = async () => {
        const token = localStorage.getItem("token");

        try {
          const response = await axios.get(
            `https://seo.digitalhive.in/seo/wp-json/custom/v1/assigned_users?project_id=${projectId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }
          );
          setAssignedUsers(response.data);
        } catch (error) {
          console.error("Error fetching assigned users:", error);
          toast.error("Failed to fetch assigned users");
        }
      };

      fetchAssignedUsers();
      fetchProject();
    }, [projectId]);

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <ToastContainer />
        {project ? (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <ProjectDetailsCard project={project} assignedUsers={assignedUsers} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <ProjectDescription project={project} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <CredentialsTab projectId={projectId} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <TaskProgress projectId={projectId} />
                </Paper>
              </Grid>
              {/* <Grid item xs={12} md={8}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <KeywordRanksLineChart projectId={projectId} />
                </Paper>  
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <KeywordPriorityList projectId={projectId} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <ReportList project={project} projectId={projectId} />
                </Paper>
              </Grid>
            </Grid>
            <FloatingActionButton projectId={projectId} currentUser={currentUser} project={project} />
          </>
        ) : (
          <div>No project data available</div>
        )}
      </Container>
    );
  };

  export default ProjectDashboard;
