import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const WorkDoneModal = ({ open, onClose, projectId }) => {
  const [allWorkDone, setAllWorkDone] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open && projectId) {
      const fetchAllWorkDone = async () => {
        try {
          const response = await axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/works-all/${projectId}`);
          setAllWorkDone(response.data);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
          toast.error("Failed to fetch work done.");
        }
      };

      fetchAllWorkDone();
    }
  }, [open, projectId]);

  const handleClose = () => {
    setAllWorkDone([]); // Reset data when modal closes
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>All Work Done</DialogTitle>
      <DialogContent>
        {loading ? (
          <Typography>Loading work done...</Typography>
        ) : error ? (
          <Typography>Error loading work done: {error.message}</Typography>
        ) : allWorkDone.length === 0 ? (
          <Typography>No work done found for this project.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Activity Type</TableCell>
                  <TableCell>Activity URL</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Tag</TableCell>
                  <TableCell>Landing Page</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allWorkDone.map((work, index) => (
                  <TableRow key={index}>
                    <TableCell>{work.id}</TableCell>
                    <TableCell>{work.activity_type}</TableCell>
                    <TableCell><a href={work.activity_url} target="_blank" rel="noopener noreferrer">{work.activity_url}</a></TableCell>
                    <TableCell>{work.user}</TableCell>
                    <TableCell>{work.title}</TableCell>
                    <TableCell>{work.tag}</TableCell>
                    <TableCell><a href={work.landing_page} target="_blank" rel="noopener noreferrer">{work.landing_page}</a></TableCell>
                    <TableCell>{work.work_description}</TableCell>
                    <TableCell>{new Date(work.date).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkDoneModal;
