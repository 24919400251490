import React from "react";
import { CardContent, CardMedia, Typography, Box, IconButton, Tooltip, Grid } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dhlogo from '../assets/dhlogo.jpg'; // Default image
import { toast } from 'react-toastify';

const ProjectDescription = ({ project }) => {
  if (!project) return null;

  const {
    acf_fields: {
      short_bio = " ",
      long_bio = "",
      images: { image1 = { url: dhlogo } } = {}, // Default image if image1 is not available
    } = {},
  } = project;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Text copied to clipboard!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }).catch((err) => {
      toast.error('Failed to copy text: ' + err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  };

  return (
    <Box>
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" component="div">
          Project Description
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="150"
        alt={image1.alt || "Project Image"}
        sx={{ objectFit: "contain" }}
        src={image1.url || dhlogo} // Fallback to default image
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography variant="body2" color="text.secondary">
              {short_bio}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Copy Short Bio to Clipboard">
              <IconButton onClick={() => copyToClipboard(short_bio)}>
                <ContentCopyIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" color="text.secondary">
              {long_bio}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Copy Main Bio to Clipboard">
              <IconButton onClick={() => copyToClipboard(long_bio)}>
                <ContentCopyIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default ProjectDescription;
