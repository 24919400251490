import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardActions, Button, Grid, CardMedia, IconButton } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import axios from 'axios';

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [viewMode, setViewMode] = useState('grid'); // Toggle between 'grid' and 'list'

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        
        // Fetch the list of assigned projects
        const response = await axios.get('https://seo.digitalhive.in/seo/wp-json/custom/v1/assigned_projects', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        const projectList = response.data;
        setProjects(projectList);

        // Fetch detailed information for each project
        const detailsPromises = projectList.map(project => 
          axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/projects/${project.ID}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        );

        const detailsResponses = await Promise.all(detailsPromises);
        const detailsMap = {};
        
        // Map the detailed responses by project ID
        detailsResponses.forEach(detailsResponse => {
          const projectDetail = detailsResponse.data;
          detailsMap[projectDetail.id] = projectDetail;
        });

        setProjectDetails(detailsMap);
      } catch (error) {
        console.error('Error fetching projects or details:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <Box sx={{ p: 4, backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ fontWeight: 600, color: '#333' }}
        >
          Projects
        </Typography>
        {/* Toggle View Icons */}
        <Box>
          <IconButton onClick={() => setViewMode('grid')}>
            <ViewModuleIcon />
          </IconButton>
          <IconButton onClick={() => setViewMode('list')}>
            <ViewListIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Conditionally Render Grid or List View */}
      {viewMode === 'grid' ? (
        <Grid container spacing={3} justifyContent="center">
          {projects.map((project) => {
            const details = projectDetails[project.ID] || {};
            const { acf_fields: { project_name, images: { image1 } = {} } = {} } = details;

            return (
              <Grid item xs={12} sm={6} md={2.8} lg={2} key={project.ID}>
                <Card 
                  sx={{
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    boxShadow: 4, 
                    borderRadius: 2, 
                    transition: 'transform 0.3s ease',
                    '&:hover': { transform: 'translateY(-5px)' } // Subtle hover effect
                  }}
                >
                  {image1?.url && (
                    <CardMedia
                      component="img"
                      sx={{ 
                        height: 120,  
                        objectFit: "contain", 
                        alignSelf: 'center', 
                        mt: 2, 
                        mb: 2 
                      }}
                      src={image1.url}
                      alt={project_name || project.post_title}
                    />
                  )}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div" sx={{ textAlign: 'center', fontWeight: 500 }}>
                      {project_name || project.post_title}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                    <Button
                      component={Link}
                      to={`/projects/${project.ID}`}
                      size="small"
                      sx={{
                        backgroundColor: '#ff5a5f', 
                        color: '#fff', 
                        '&:hover': { backgroundColor: '#ff3a3f' }
                      }}
                    >
                      View Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        // List View
        <Box>
          {projects.map((project) => {
            const details = projectDetails[project.ID] || {};
            const { acf_fields: { project_name } = {} } = details;

            return (
              <Box 
                key={project.ID} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 2, 
                  p: 2, 
                  border: '1px solid #ddd', 
                  borderRadius: 2,
                  backgroundColor: '#fff',
                  boxShadow: 2,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                    transform: 'scale(1.02)'
                  }
                }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold', color: '#555' }}>
                  {project_name || project.post_title}
                </Typography>
                <Button
                  component={Link}
                  to={`/projects/${project.ID}`}
                  size="small"
                  sx={{
                    backgroundColor: '#ff5a5f', 
                    color: '#fff', 
                    '&:hover': { backgroundColor: '#ff3a3f' }
                  }}
                >
                  View Details
                </Button>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default ProjectList;
