  // src/pages/UserProfile.js
  import React, { useEffect, useState } from 'react';
  import axios from 'axios';
  import { Box, Typography, Paper, Avatar, Grid, Divider, CircularProgress } from '@mui/material';
  import { toast } from 'react-toastify';


  const UserProfile = () => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('https://seo.digitalhive.in/seo/wp-json/wp/v2/users/me', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
          toast.error('Failed to fetch user data');
        }
      };

      fetchUserData();
    }, []);

    if (!userData) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Avatar alt={userData.name} src={userData.avatar_urls[96]} sx={{ width: 128, height: 128 }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                {userData.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                {userData.description}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Email: {userData.email}
              </Typography>
            
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Additional Information
              </Typography>
              {/* Add more fields as needed */}
              <Typography variant="body2" color="textSecondary">
                Registered: {new Date(userData.registered_date).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  };

  export default UserProfile;
