import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';

const getColor = (rank) => {
  if (rank == 0) return '#7c7c70'; // grey
  if (rank <= 10) return '#1dd1a1'; // green
  if (rank <= 50) return '#d0d22d'; // yellow
  if (rank <= 100) return '#ffa502'; // orange
  return '#ff6b6b'; // red
};

const KeywordPriorityList = ({ projectId }) => {
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    if (projectId) {
      axios.get(`https://seo.digitalhive.in/seo/wp-json/custom/v1/keywords/${projectId}`)
        .then(response => {
          setKeywords(response.data);
        })
        .catch(error => {
          console.error('Error fetching project keywords:', error);
        });
    }
  }, [projectId]);

  const sortedKeywords = keywords.sort((a, b) => b.rankVal - a.rankVal);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" component="div" gutterBottom>
        Keywords Prioritization
      </Typography>
      <List>
        {sortedKeywords.map((item, index) => (
          <ListItem key={index} sx={{
            border: 1,
            borderColor: getColor(item.rankVal),
            mb: 1,
            borderRadius: 1,
            bgcolor: 'transparent' // Ensures the background is transparent
          }}>
            <ListItemText
              primary={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ color: getColor(item.rankVal) }}>
                    {item.keyword}
                  </Typography>
                  <Typography variant="body1" sx={{ color: getColor(item.rankVal), mr: 2 }}>
                    Rank: {item.rankVal}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default KeywordPriorityList;
